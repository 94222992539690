import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';
import baseUrl from '@root/config';
import queries from '@queries';

const Login = ({ children, isAdmin = false }) => {
  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  if (me?.isError) window.location.replace(baseUrl + '/api/connect/google');

  return me?.isLoading ? (
    <Loading src={loadingSvg} className="max-w-[10rem]" />
  ) : isAdmin ? (
    me.data.isAdmin && me?.isSuccess ? (
      children
    ) : (
      <Loading src={loadingSvg} className="max-w-[10rem]" />
    )
  ) : (
    me?.isSuccess && children
  );
};

export default Login;
