import { DropDown, Button } from '@components';
import { useState } from 'react';
import moment from 'moment';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { Page } from '@layouts';

import { useQuery } from '@tanstack/react-query';
import baseUrl from '@root/config';
import queries from '@queries';
import { AdminCommon } from '../../layouts/Page/AdminCommon';
import { AdminCommonComponents } from '../../layouts';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: false },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: false
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: true },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: false },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Summary() {
  const [month, setMonth] = useState(moment().month());
  const [selectedUser, setSelectedUser] = useState({});

  const { data: teamsData, isLoading: teamsDataIsLoading } = useQuery(['teams'], () =>
    queries.getAll('/api/teams', {
      populate: ['users_permissions_user', 'time_entries', 'project', 'project.template']
    })
  );

  const { data: monthSheetData, isLoading: monthSheetDataIsLoading } = useQuery(
    ['monthSheet', month],
    () =>
      queries.getAll('/api/time-entries', {
        populate: ['team', 'team.project', 'team.users_permissions_user'],
        filters: {
          date: {
            $gte: moment().month(month).startOf('month').format('yyyy-MM-DD'),
            $lte: moment().month(month).endOf('month').format('yyyy-MM-DD')
          }
        }
      })
  );

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['users'], () =>
    queries.getAll('/api/users')
  );

  if (teamsDataIsLoading || monthSheetDataIsLoading || usersDataIsLoading) return <></>;

  const _generateMonthsList = () =>
    new Array(12).fill(0).map((_, index) => ({
      label: `Month ${index + 1} - ${moment()
        .month(index)
        .startOf('month')
        .format('DD/MM/yyyy')} - ${moment().month(index).endOf('month').format('DD/MM/yyyy')}`,
      value: index
    }));

  const getTimeEntry = (t) =>
    monthSheetData?.data
      ?.filter((m) => m?.team?.id == t.id)
      .reduce((a, b) => a + parseFloat(b.value), 0) * t.dailyRate;

  const filterTeamsUsingComboBox = (teams) =>
    teams.filter(
      (t) =>
        !selectedUser?.username ||
        t?.users_permissions_user?.username
          ?.toLowerCase()
          ?.includes(selectedUser?.username?.toLowerCase())
    );

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: false },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: true, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <div className="lg:col-span-9">
                <AdminCommon
                  hideEdit
                  pageTitle="Summary"
                  pageSubTitle="Aggregation of the time entries"
                  setSelectedId={setSelectedUser}
                  tabelLabels={['User', 'Project', 'Daily Rate', 'Time entries', 'Billing', '', '']}
                  tableData={filterTeamsUsingComboBox(teamsData?.data)
                    .filter(
                      (t) =>
                        t?.time_entries
                          ?.filter(
                            (te) =>
                              moment(te?.date).format('M') == month + 1 &&
                              moment(te?.date).format('YYYY') == moment().year()
                          )
                          .reduce((a, b) => a + parseFloat(b?.value), 0) != 0
                    )
                    .map((t) => ({
                      id: t?.id,
                      userName: t?.users_permissions_user?.username,
                      team: t?.project?.name,
                      dailyRate: t?.dailyRate,
                      timeEntries: monthSheetData?.data
                        ?.filter((m) => m?.team?.id == t?.id)
                        .reduce((a, b) => a + parseFloat(b.value), 0),
                      billing: getTimeEntry(t),
                      link: t?.project?.template ? (
                        <a
                          className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
                          href={baseUrl + `/api/template/fill?team=${t?.id}&month=${month + 1}`}
                          target="_blank"
                          rel="noreferrer">
                          Fill template
                        </a>
                      ) : null,
                      quickBook: t?.itemRef ? (
                        <a
                          className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
                          href={
                            baseUrl + `/api/template/quickbooks?team=${t?.id}&month=${month + 1}`
                          }
                          target="_blank"
                          rel="noreferrer">
                          insert QBook
                        </a>
                      ) : null
                    }))}
                  additionalFront={
                    <>
                      <div className="mt-4 w-full">
                        <p className="text-sm font-semibold text-gray-900">Filter Month:</p>
                        <DropDown
                          className="mt-3 w-full"
                          name="users_permissions_user"
                          selectName=""
                          data={_generateMonthsList()}
                          required={false}
                          defaultValue={moment().month()}
                          onChange={(e) => {
                            setMonth(parseInt(e.target.value));
                          }}
                        />
                      </div>
                      <AdminCommonComponents
                        usersData={usersData}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                      />
                    </>
                  }></AdminCommon>
                <Button
                  type="submit"
                  color="cyan"
                  onClick={() => {}}
                  className="lg:ml-8 mb-8 md:ml-6 text-white font-bold py-2 px-4 rounded">
                  <a
                    className="text-white"
                    href={baseUrl + `/api/template/payroll?month=${month}`}
                    target="_blank"
                    rel="noreferrer">
                    Generate payroll
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
