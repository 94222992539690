import { Table, Modal, InputText, Form, Button, Alert, DropDown, Toggle } from '@components';
import { useEffect, useState } from 'react';
import { Page } from '@layouts';
import { Dialog } from '@headlessui/react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import queries from '@queries';
import Compteur from './Compteur';

export default function TimeOff() {
  const [timeOffIdSelected, setTimeOffIdSelected] = useState();
  const [openModal, setOpenModal] = useState(false);

  const upsertTimeOff = useMutation((data) => queries.post('/api/time-offs', data));

  const deleteTimeOffsMutation = useMutation((data) => queries.delete('/api/time-offs', data));

  const currentUser = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  const { data: usersWithHolidays, isLoading: usersWithHolidaysIsLoading } = useQuery(
    ['usersWithHolidays'],
    () =>
      queries.getAll('/api/timeoff/holidaysGained', {
        usersId: [currentUser?.data?.id]
      })
  );

  const { data: usersDaysOffTaken, isLoading: usersDaysOffTakenIsLoading } = useQuery(
    ['usersDaysOffTaken'],
    () =>
      queries.getAll('/api/timeoff/daysOffTaken', {
        usersId: [currentUser?.data?.id]
      })
  );

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState(upsertTimeOff);
  const [policyError, setPolicyError] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState('');
  const [endDateSelected, setEndDateSelected] = useState('');

  const location = useLocation();
  const timeoffObject = location.state;

  useEffect(() => {
    if (timeoffObject?.firstDate && timeoffObject?.lastDate) {
      setOpenModal(true);
    }
  }, [timeoffObject?.firstDate, timeoffObject?.lastDate]);

  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  const { data: timeoffData, isLoading: timeoffDataIsLoading } = useQuery(['timeoff'], () =>
    queries.getAll('/api/time-offs', { populate: ['users_permissions_user'] })
  );

  if (timeoffDataIsLoading || usersWithHolidaysIsLoading || usersDaysOffTakenIsLoading)
    return <></>;

  const sortTimeOff = (t) =>
    t?.sort((a, b) => moment(b?.updatedAt).unix() - moment(a?.updatedAt).unix());

  return (
    <>
      <Page
        navigation={[
          { name: 'Home', href: '/', current: false },
          { name: 'Time Tracker', href: '/time-tracker', current: false },
          { name: 'Time off', href: '/time-off', current: true },
          { name: 'Admin', href: '/admin/projects', current: false, isAdmin: true }
        ]}>
        <main className="-mt-24 pb-8">
          <Compteur
            cpCredit={usersWithHolidays?.filter((u) => u?.id == currentUser?.data?.id)?.[0]?.cp}
            enAttente={usersDaysOffTaken[currentUser?.data?.id]?.holidaysEnAttente}
            daysOffTaken={usersDaysOffTaken[currentUser?.data?.id]?.holidaysTaken}
          />
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="lg:col-span-9 px-4">
                <div className="mt-4 sm:mt-6 lg:mt-8">
                  <div className="flex items-center justify-between px-8">
                    <div className="sm:flex-auto ">
                      <h1 className="text-xl font-semibold text-gray-900">Time off</h1>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                        onClick={() => {
                          setOpenModal(true);
                          setTimeOffIdSelected(null);
                          setIsError(false);
                          setPolicyError(false);
                          delete timeoffObject?.firstDate;
                          delete timeoffObject?.lastDate;
                        }}>
                        Add Time off
                      </button>
                    </div>
                  </div>
                </div>
                <Table
                  className={'divide-y divide-gray-600 mb-10'}
                  classNameBanner={'bg-gray-100'}
                  data={sortTimeOff(
                    timeoffData?.data?.filter((t) => t?.users_permissions_user?.id == me?.data?.id)
                  )?.map((o) => ({
                    date: o?.startDate,
                    endDate: o?.endDate,
                    halfDay: <Toggle name={o?.id} checked={o?.halfDay} disabled />,
                    type: o?.type,
                    status: (
                      <span
                        className={`inline-flex items-center rounded-full bg-${
                          { 'En attente': 'yellow', Validé: 'green', Refusé: 'red' }[o?.status]
                        }-100 px-2.5 py-0.5 text-xs font-medium text-${
                          { 'En attente': 'yellow', Validé: 'green', Refusé: 'red' }[o?.status]
                        }-800`}>
                        {o?.status}
                      </span>
                    )
                  }))}
                  onClick={(e) => {
                    setOpenModal(true);
                    setTimeOffIdSelected(
                      sortTimeOff(
                        timeoffData.data?.filter(
                          (t) => t?.users_permissions_user?.id == me?.data?.id
                        )
                      )[e].id
                    );
                    setIsError(false);
                    setPolicyError(false);
                  }}
                  labels={['Start date', 'End Date', 'Half Day', 'Type', 'Status']}
                />
                <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                  <Form
                    mutation={mutation}
                    sendErrorObject={(e) =>
                      e?.response?.data?.error?.message === 'policy invalid for dates'
                        ? setPolicyError(true)
                        : ''
                    }
                    callback={(r) => {
                      console.log(r);
                      queryClient.invalidateQueries('timeoff');
                      queryClient.invalidateQueries('project');
                      setOpenModal(false);
                    }}
                    setIsError={setIsError}
                    setIsLoading={setIsLoading}>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <input type="hidden" name="id" value={timeOffIdSelected}></input>
                        {isError && !policyError && (
                          <Alert color="red" className="mb-4 mt-2">
                            Impossible to create a time off.
                            <br />
                            Make sure to be connected to internet
                          </Alert>
                        )}
                        <Alert color="yellow" className="mb-4 mt-2">
                          Demande de congés inférieure à 3 jours consécutifs minimum 1 semaine
                          avant.
                          <br />
                          Demande de congés supérieure à 3 jours consécutifs minimum 1 mois avant.
                        </Alert>
                        {policyError && (
                          <Alert color="red" className="mb-4 mt-2">
                            La demande de congés ne semble pas correspondre à la politique
                            mentionnée.
                            <br />
                            Veuillez vous rapprocher de votre manager
                          </Alert>
                        )}
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-semibold text-gray-900">
                          Create or update a time off
                        </Dialog.Title>

                        <input
                          type="hidden"
                          name="users_permissions_user"
                          value={me.data?.id}></input>

                        <div className="flex items-center text-sm lg:text-base mt-3">
                          Start date
                        </div>
                        <InputText
                          required={false}
                          className="grow col-span-3"
                          name="startDate"
                          defaultValue={
                            timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]
                              ?.startDate || timeoffObject?.firstDate
                          }
                          classNameInput={'rounded'}
                          type="date"
                          onChange={(e) => setStartDateSelected(e.target.value)}
                        />

                        <div className="flex items-center text-sm lg:text-base mt-3">End date</div>
                        <InputText
                          required={false}
                          className="grow col-span-3"
                          name="endDate"
                          defaultValue={
                            timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]
                              ?.endDate || timeoffObject?.lastDate
                          }
                          classNameInput={'rounded'}
                          type="date"
                          onChange={(e) => setEndDateSelected(e.target.value)}
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Half day
                        </div>
                        <Toggle
                          disabled={
                            (startDateSelected ||
                              timeoffObject?.firstDate ||
                              timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]
                                ?.startDate) !==
                            (endDateSelected ||
                              timeoffObject?.lastDate ||
                              timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]
                                ?.endDate)
                          }
                          name={'halfDay'}
                          className="mt-3"
                          defaultChecked={
                            timeOffIdSelected
                              ? timeoffData?.data?.filter((u) => u.id == timeOffIdSelected)?.[0]
                                  ?.halfDay
                              : false
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Type
                        </div>
                        <DropDown
                          className="mt-3 w-full"
                          name="type"
                          selectName=""
                          data={[
                            {
                              label: 'Congés payés',
                              value: 'Congés payés'
                            },
                            {
                              label: 'Congés sans solde',
                              value: 'Congés sans solde'
                            }
                          ]}
                          required={true}
                          defaultValue={
                            timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]?.type
                          }
                        />
                        <input name="status" type="hidden" value="En attente" />
                      </div>
                    </div>
                    <div
                      className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center sm:justify-between`}>
                      {timeOffIdSelected ? (
                        <Button
                          color="red"
                          onClick={() => setMutation(deleteTimeOffsMutation)}
                          className="text-white font-bold py-2 px-4 rounded"
                          isLoading={isLoading}
                          isWrong={isError}>
                          Delete
                        </Button>
                      ) : (
                        <Button
                          form="null"
                          color="gray"
                          onClick={() => setOpenModal(false)}
                          className="text-white font-bold py-2 px-4 rounded">
                          Cancel
                        </Button>
                      )}
                      <Button
                        type="submit"
                        color="cyan"
                        onClick={() => setMutation(upsertTimeOff)}
                        className="text-white font-bold py-2 px-4 rounded"
                        isLoading={isLoading}
                        isWrong={isError}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </div>
            </div>
          </div>
        </main>
      </Page>
    </>
  );
}
