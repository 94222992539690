import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import baseUrl from '@root/config';

function GoogleAuthCallback() {
  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    const { search } = location;

    axios({
      method: 'GET',
      url: `${baseUrl}/api/auth/google/callback${search}`
    }).then((res) => {
      localStorage.setItem('token', res?.data?.jwt);
      queryClient.invalidateQueries('me');
      window.location.replace('/');
    });
  }, [location]);
}

export default GoogleAuthCallback;
