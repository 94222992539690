import '@root/locale';

export default function Compteur(props) {
  const { cpCredit = 0, daysOffTaken = 0, enAttente = 0 } = props;

  return (
    <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16 ">
      <div className="overflow-hidden rounded-lg bg-white shadow p-4">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Annual leave.</h1>
              <p className="mt-2 text-sm text-gray-700">
                This is the list of your annual leave, the ones you still have to take, the ones
                pending or the ones validated, and your balance.
              </p>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6">
                          Credit
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                          Pending
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                          Validated
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 font-bold text-gray-900 sm:pl-6">
                          {cpCredit}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 font-bold text-gray-800">
                          {enAttente}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 font-bold text-gray-800">
                          {daysOffTaken}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 font-bold text-red-500">
                          {cpCredit - daysOffTaken}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
