import moment from 'moment';

export const _generateWeekNumbers = () =>
  new Array(52).fill(0).map((_, index) => ({
    label: `Week ${index + 1} - ${moment()
      .week(index + 1)
      .startOf('week')
      .format('DD/MM/yyyy')} - ${moment()
      .week(index + 1)
      .endOf('week')
      .format('DD/MM/yyyy')}`,
    value: index + 1
  }));

export const _generateDays = (week) =>
  new Array(7)
    .fill(0)
    .map((_, index) => moment().week(week).startOf('week').add(index, 'd').format('DD/MM'));

export const fillValuesFromDB = (rowElement, colIndex, weekSheets, week) => {
  return weekSheets?.filter(
    (o) =>
      o?.team === rowElement?.id &&
      o?.date === moment().week(week).startOf('week').add(colIndex, 'd').format('yyyy-MM-DD')
  )?.[0]?.value;
};
