import { useEffect, useState } from 'react';
import { Page } from '@layouts';
import { DropDown, Button, Alert } from '@components';
import moment from 'moment';
import '@root/locale';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CheckCircleIcon,
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon
} from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';
import queries from '@queries';
import CalendarMonth from './CalenderMonth';
import { checkIfWeekEnd, checkIfPublicHoliday, checkIfSickDay } from './coloringFunctions';
import { _generateDays, _generateWeekNumbers, fillValuesFromDB } from './generateTableItems';

export default function TimeTracker() {
  const [week, setWeek] = useState(moment().week());

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [viewCalendarBy, setViewCalendarBy] = useState('week');

  const queryClient = useQueryClient();

  const currentUser = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  const upsertTimeEntry = useMutation((data) => queries.post('/api/time-entries', data));

  const { data: teamsData, isLoading: teamsDataIsLoading } = useQuery(['team'], () =>
    queries.getAll('/api/teams', {
      populate: ['users_permissions_user', 'time_entries', 'project', 'project.template'],
      filters: {
        users_permissions_user: {
          id: currentUser?.data?.id
        }
      }
    })
  );

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['users'], () =>
    queries.getAll('/api/users', {
      populate: ['time_offs', 'sick_days']
    })
  );

  const { data: monthSheetData, isLoading: monthSheetDataIsLoading } = useQuery(
    ['monthsheet', week],
    () =>
      queries.getAll('/api/time-entries', {
        populate: ['team'],
        filters: {
          team: {
            users_permissions_user: {
              id: currentUser?.data?.id
            }
          },
          date: {
            $gte: moment().week(week).startOf('week').format('yyyy-MM-DD'),
            $lte: moment().week(week).endOf('week').format('yyyy-MM-DD')
          }
        }
      })
  );

  const { data: daysOffData, isLoading: daysOffDataIsLoading } = useQuery(['daysoff'], () =>
    queries.getAll('/api/days-offs')
  );
  const [weekSheets, setWeekSheets] = useState(
    monthSheetData?.data.map((ms) => ({ ...ms, team: ms.team.id })) || []
  );

  useEffect(() => {
    if (monthSheetData?.data.length > 0) {
      setWeekSheets(monthSheetData?.data.map((ms) => ({ ...ms, team: ms.team.id })));
    }
  }, [monthSheetData?.data]);

  if (teamsDataIsLoading || monthSheetDataIsLoading || daysOffDataIsLoading || usersDataIsLoading)
    return <></>;

  const editTableRows = (value, colIndex, idTeam) => {
    const date = moment().week(week).startOf('week').add(colIndex, 'd').format('yyyy-MM-DD');

    // Update
    if (weekSheets?.filter((o) => o?.team === idTeam && o?.date === date)?.length > 0) {
      const id = weekSheets?.filter((o) => o?.team === idTeam && o?.date === date)?.[0]?.id;
      const temp = structuredClone(weekSheets);
      temp.filter((t) => t.id === id)[0].value = value;
      setWeekSheets(temp);
    }
    // Insert
    else {
      setWeekSheets((prev) => [
        ...prev,
        {
          id: uuidv4(),
          value,
          date,
          team: idTeam
        }
      ]);
    }
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      await upsertTimeEntry.mutateAsync(weekSheets);

      queryClient.invalidateQueries('monthsheet');
      setIsError(false);
      setIsLoading(false);
      setSuccess(true);
      setTimeout(function () {
        setSuccess(false);
      }, 5 * 1000);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      console.log(e);
    }
  };

  const countWorkingDays = (Weeks) =>
    Weeks.map((w) => parseFloat(w.value)).reduce((partialSum, a) => partialSum + a, 0);

  const autoFillRow = (rowElement) => {
    const temp = structuredClone(weekSheets);
    for (let i = 0; i < 5; i++) {
      const date = moment().week(week).startOf('week').add(i, 'd').format('yyyy-MM-DD');
      if (temp.filter((o) => o?.team === rowElement.id && o?.date === date)[0]) {
        temp.filter((o) => o?.team === rowElement.id && o?.date === date)[0].value = 1;
      } else {
        temp.push({
          id: uuidv4(),
          date: date,
          value: 1,
          team: teamsData?.data?.filter((t) => t.id == rowElement.id)[0]?.id
        });
      }
    }
    setWeekSheets(temp);
  };

  return (
    <>
      <Page
        navigation={[
          { name: 'Home', href: '/', current: false },
          { name: 'Time Tracker', href: '/time-tracker', current: true },
          { name: 'Time off', href: '/time-off', current: false },
          { name: 'Admin', href: '/admin/projects', current: false, isAdmin: true }
        ]}>
        <main className="-mt-24 pb-8">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              {success && (
                <Alert color="green" className="mt-4 mx-3" icon={CheckCircleIcon}>
                  Data updated succesfully.
                </Alert>
              )}
              {isError && (
                <Alert color="red" className="mt-4">
                  Failed to update.
                  <br />
                  Make sure to be connected to internet
                </Alert>
              )}
              <div className="px-4 sm:px-6 lg:px-8 mt-4 sm:mt-6 lg:mt-8">
                <div className="flex items-center text-sm lg:text-base mt-3 font-medium text-gray-900">
                  <p className="flex-auto">Choose week</p>
                  <Button
                    onClick={() => setWeek(parseInt(week) - 1)}
                    textColor="gray"
                    color="white"
                    className="border-gray-300 hover:bg-gray-50">
                    <ArrowSmallLeftIcon className="h-6 w-6 text-gray-500" />
                  </Button>

                  <Button
                    onClick={() => setWeek(parseInt(week) + 1)}
                    textColor="gray"
                    color="white"
                    className="border-gray-300 hover:bg-gray-50 ml-2">
                    <ArrowSmallRightIcon className="h-6 w-6 text-gray-500 " />
                  </Button>
                </div>
                <DropDown
                  className="mt-3 w-full"
                  name="users_permissions_user"
                  selectName=""
                  data={_generateWeekNumbers()}
                  defaultValue={parseInt(week)}
                  required={false}
                  onChange={(e) => setWeek(e.target.value)}
                  value={parseInt(week)}
                  isControlled
                />
                <div className="table-responsive mt-4 pb-0">
                  <table className="table table-hover w-full border-collapse">
                    <thead>
                      <tr>
                        <th className="text-left text-sm font-semibold text-gray-900">Days:</th>
                        {_generateDays(week).map((e, daysIndex) => (
                          <th
                            className="text-center px-3 text-sm font-semibold text-gray-900"
                            key={daysIndex}>
                            {e}
                          </th>
                        ))}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamsData?.data
                        ?.sort(
                          (a, b) =>
                            parseInt(a?.project?.displayRank) - parseInt(b?.project?.displayRank)
                        )
                        .map((rowElement) => {
                          return (
                            <tr key={rowElement?.project?.id}>
                              <td className="text-sm text-gray-500">{rowElement?.project?.name}</td>
                              {new Array(7).fill(0).map((_, colIndex) => {
                                return (
                                  <td key={colIndex}>
                                    <select
                                      disabled={
                                        rowElement?.project?.name == 'Sick days' ||
                                        rowElement?.project?.name == 'Holidays'
                                      }
                                      className={
                                        'border-collapse form-select w-full appearance-none block grow my-1 py-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ' +
                                        checkIfWeekEnd(colIndex, week) +
                                        ` ${checkIfPublicHoliday(colIndex, daysOffData, week)}` +
                                        ` ${checkIfSickDay(rowElement, colIndex, week)}`
                                      }
                                      value={
                                        fillValuesFromDB(rowElement, colIndex, weekSheets, week) ||
                                        0
                                      }
                                      onChange={(e) =>
                                        editTableRows(e.target.value, colIndex, rowElement?.id)
                                      }>
                                      <option value={'0'}>0</option>
                                      <option value={'0.5'}>0.5</option>
                                      <option value={'1'}>1</option>
                                    </select>
                                  </td>
                                );
                              })}
                              {rowElement?.project?.name !== 'Sick days' &&
                                rowElement?.project?.name !== 'Holidays' && (
                                  <td className="text-center">
                                    <Button
                                      color="cyan"
                                      onClick={() => autoFillRow(rowElement)}
                                      className="text-white py-2 px-3 rounded">
                                      Fill
                                    </Button>
                                  </td>
                                )}
                              <td className="text-center pt-1 text-sm text-gray-500 w-[64px]">
                                {countWorkingDays(
                                  weekSheets.filter((o) => o?.team?.id === rowElement?.id)
                                )}
                                <span> / 5</span>
                              </td>
                            </tr>
                          );
                        })}
                      <tr className="h-[50px]">
                        <td colSpan={8}></td>
                        <td className="text-center pt-1 text-sm text-gray-500 font-semibold w-[64px]">
                          {countWorkingDays(weekSheets)}
                          <span> / 5</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="pb-3 sm:flex justify-between ">
                  <div></div>
                  <div>
                    <Button
                      type="submit"
                      color="cyan"
                      onClick={() => submit()}
                      className="text-white font-bold py-2 px-4 rounded"
                      isLoading={isLoading}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {viewCalendarBy === 'week' && (
            <CalendarMonth
              week={week}
              setWeek={setWeek}
              users={usersData}
              daysOffData={daysOffData}
              setViewCalendarBy={setViewCalendarBy}
              viewCalendarBy={viewCalendarBy}
            />
          )}
          {viewCalendarBy === 'month' && (
            <CalendarMonth
              setWeek={setWeek}
              showAllMonth={true}
              users={usersData}
              daysOffData={daysOffData}
              setViewCalendarBy={setViewCalendarBy}
              viewCalendarBy={viewCalendarBy}
            />
          )}
        </main>
      </Page>
    </>
  );
}
