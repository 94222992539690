import moment from 'moment';

export const checkIfWeekEnd = (col, week) => {
  const dayOfWeek = moment(
    moment().week(week).startOf('week').add(col, 'd'),
    'DD/MM/YYYY'
  ).isoWeekday();
  return dayOfWeek == 6 || dayOfWeek == 7 ? 'bg-gray-300' : '';
};

export const checkIfPublicHoliday = (col, daysOffData, week) =>
  daysOffData?.data
    ?.map((d) => d?.date)
    .includes(moment().week(week).startOf('week').add(col, 'd').format('YYYY-MM-DD'))
    ? 'bg-rose-200'
    : '';

export const checkIfSickDay = (elem, col, week) =>
  elem?.time_entries
    ?.map((d) => d?.date)
    .includes(moment().week(week).startOf('week').add(col, 'd').format('YYYY-MM-DD')) &&
  elem?.project?.name == 'Sick days'
    ? 'bg-green-200'
    : '';
