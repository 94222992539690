import { CheckBadgeIcon, ClockIcon, TrashIcon, SunIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { Page } from '@layouts';
import queries from '@queries';
import { Button } from '@components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import moment from 'moment';

const actions = [
  {
    icon: ClockIcon,
    name: 'Fill the TimeTracker',
    href: '/time-tracker',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: 'Click here to access the TimeTracker sheet and fill your monthly presence.'
  },
  {
    icon: CheckBadgeIcon,
    name: 'Leaves',
    href: '/time-off',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    description: 'Click here to access the Leaves form to request leaves.'
  },
  {
    icon: SunIcon,
    name: 'Remaining Holidays',
    href: '/time-off',
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    description: 'Click here to access the holidays calendar.'
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HomePage() {
  const queryClient = useQueryClient();

  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  const { data: newsData, isLoading: newsDataIsLoading } = useQuery(['news'], () =>
    queries.getAll('/api/news')
  );

  const upsertSickDaysMutation = useMutation((data) => queries.post('/api/sick-days', data));
  const upsertNotificationsMutation = useMutation((data) =>
    queries.post('/api/notifications', data)
  );

  const { data: sickDaysData, isLoading: sickDaysDataIsLoading } = useQuery(['sickdays'], () =>
    queries.getAll('/api/sick-days', { populate: ['ordonnance', 'users_permissions_user'] })
  );

  const { data: notificationsData, isLoading: notificationsDataIsLoading } = useQuery(
    ['notifications'],
    () => queries.getAll('/api/notifications', { populate: ['users_permissions_user'] })
  );

  const [sickDays, setSickDays] = useState(sickDaysData?.data || []);
  const [notifications, setNotifications] = useState(notificationsData?.data || []);

  useEffect(() => {
    if (sickDaysData?.data.length > 0) {
      setSickDays(sickDaysData?.data);
    }
    if (notificationsData?.data.length > 0) {
      setNotifications(notificationsData?.data);
    }
  }, [sickDaysData?.data, notificationsData?.data]);

  const renderClock = () => {
    document.querySelector('#clock-div').innerHTML = `${moment().format('HH:mm')}`;
  };

  useEffect(() => {
    setInterval(renderClock, 1000);
  }, []);

  if (sickDaysDataIsLoading || newsDataIsLoading || notificationsDataIsLoading) return <></>;

  const updateView = async (item) => {
    const temp = structuredClone(item?.phrase ? notifications : sickDays);
    temp.filter((o) => o.id == item?.id)[0].hideToUser = true;
    item?.phrase ? setNotifications(temp) : setSickDays(temp);
    item?.phrase
      ? await upsertNotificationsMutation.mutateAsync(
          [temp.filter((o) => o.id == item?.id)[0]].map((o) => ({
            ...o,
            phrase: o?.phrase?.id,
            users_permissions_user: o?.users_permissions_user?.id
          }))
        )
      : await upsertSickDaysMutation.mutateAsync(
          [temp.filter((o) => o.id == item?.id)[0]].map((o) => ({
            ...o,
            ordonnance: o?.ordonnance?.id,
            users_permissions_user: o?.users_permissions_user?.id
          }))
        );
    queryClient.invalidateQueries('sickdays');
    queryClient.invalidateQueries('notifications');
  };

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: true },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: false, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Profile</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Welcome panel */}
              <div className="rounded-lg bg-white overflow-hidden shadow">
                <h2 className="sr-only" id="profile-overview-title">
                  Profile Overview
                </h2>
                <div className="bg-white p-6 flex justify-between">
                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        <img
                          className="mx-auto h-20 w-20 rounded-full"
                          src={me?.data?.picture}
                          alt=""
                        />
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                          {me?.data?.username}
                        </p>
                        <p className="text-sm font-medium text-gray-600">{me?.data?.job}</p>
                        <p className="text-sm font-medium text-gray-600">
                          {' '}
                          Entry date: {me?.data?.dateEntry}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-cyan-600 p-4 rounded">
                    <div id="clock-div" className="text-white text-center text-bold text-3xl"></div>
                    <div className="text-white text-xl">{moment().format('dddd DD MMM YYYY')}</div>
                  </div>
                </div>
              </div>

              {/* Actions panel */}
              <div className="rounded-lg overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px shadow">
                <h2 className="sr-only" id="quick-links-title">
                  Quick links
                </h2>
                {actions.map((action, actionIdx) => (
                  <div
                    key={action.name}
                    className={classNames(
                      actionIdx === 0
                        ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none  shadow'
                        : '',
                      actionIdx === 1,
                      actionIdx === actions.length - 2,
                      actionIdx === actions.length - 1
                        ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                        : '',
                      'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500  shadow'
                    )}>
                    <div>
                      <span
                        className={classNames(
                          action.iconBackground,
                          action.iconForeground,
                          'rounded-lg inline-flex p-3 ring-4 ring-white'
                        )}>
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-lg font-medium">
                        <a href={action.href} className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span className="absolute inset-0" aria-hidden="true" />
                          {action.name}
                        </a>
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">{action.description}</p>
                    </div>
                    <span
                      className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                      aria-hidden="true">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-4 justify-between w-full">
                {newsData?.data?.length > 0 && (
                  <div className="bg-white  shadow rounded w-full">
                    <div className="bg-white p-6 rounded-lg ">
                      <h1 className="font-bold text-lg border-b-2 pb-4">News</h1>
                      {newsData?.data
                        ?.sort((a, b) => moment(b?.date).unix() - moment(a?.date).unix())
                        .map((o) => {
                          return (
                            <div key={o?.title}>
                              <div className="flex justify-between  mt-5">
                                <p className="font-semibold text-base text-gray-900">{o?.title}</p>
                                <p className="text-sm font-semibold">{o?.date}</p>
                              </div>
                              <p className="font-semibold mt-3 text-sm text-gray-500 pb-5 text-left w-full h-auto border-b-2">
                                {o?.phrase}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                {(sickDays?.length > 0 &&
                  sickDays?.some(
                    (o) => o?.hideToUser == false && me?.data?.id == o?.users_permissions_user?.id
                  )) ||
                (notifications?.length > 0 &&
                  notifications?.some(
                    (o) => o?.hideToUser == false && me?.data?.id == o?.users_permissions_user?.id
                  )) ? (
                  <div className="bg-white  shadow rounded w-full">
                    <div className="bg-white p-6 rounded-lg ">
                      <h1 className="font-bold text-lg">Notifications</h1>
                      {sickDays
                        ?.filter(
                          (o) =>
                            o?.hideToUser == false && me?.data?.id == o?.users_permissions_user?.id
                        )
                        .map((o, index) => {
                          return (
                            <div key={index} className="flex justify-between mt-5  border-b-2">
                              <p className="w-10/12 font-semibold mt-3 text-sm text-gray-500 pb-5 text-left w-full h-auto">
                                your request for sick day starting {o?.startDate} until {o?.endDate}{' '}
                                has been accepted.
                              </p>
                              <div className="w-1/12 flex items-center">
                                <Button
                                  onClick={() => updateView(o)}
                                  textColor="gray"
                                  color="white"
                                  className="border-gray-300 hover:bg-gray-50">
                                  <TrashIcon className="h-5 w-5 text-gray-500" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      {notifications
                        ?.filter(
                          (o) =>
                            o?.hideToUser == false && me?.data?.id == o?.users_permissions_user?.id
                        )
                        .map((o, index) => {
                          return (
                            <div key={index} className="flex mt-5  border-b-2">
                              <p className="w-9/12 font-semibold mt-3 text-sm text-gray-500 pb-5 text-left w-full h-auto ">
                                {o?.phrase}
                              </p>
                              <div className="w-2/12 font-semibold text-sm flex items-center">
                                {o?.date}
                              </div>
                              <div className="flex w-1/12 items-center">
                                <Button
                                  onClick={() => updateView(o)}
                                  textColor="gray"
                                  color="white"
                                  className="border-gray-300 hover:bg-gray-50">
                                  <TrashIcon className="h-5 w-5 text-gray-500" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
