import { Table } from '@components';

export function AdminCommon(props) {
  const {
    children = null,
    pageTitle,
    pageSubTitle,
    tableLabels,
    tableData,
    setSelectedId,
    buttonText = null,
    setOpenModal,
    setIsError,
    additionalFront,
    hideEdit
  } = props;

  return (
    <div className="lg:col-span-9">
      <div className="px-4 sm:px-6 lg:px-8 mt-4 sm:mt-6 lg:mt-8">
        <div className="flex items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{pageTitle}</h1>
            {pageSubTitle && <p className="mt-2 text-sm text-gray-700">{pageSubTitle}</p>}
          </div>
          {buttonText && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => {
                  setOpenModal(true);
                  setSelectedId(null);
                  setIsError(false);
                }}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
        {additionalFront}
      </div>

      <Table
        className={'divide-y divide-gray-200 lg:col-span-9 mb-10 px-6 lg:px-8 sm:px-2'}
        data={tableData}
        onClick={(e) => {
          setOpenModal(true);
          setSelectedId(tableData?.[e].id);
          setIsError(false);
        }}
        labels={tableLabels}
        hideEdit={hideEdit}
      />
      {children}
    </div>
  );
}
