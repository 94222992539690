import { Modal, Form, Button, Alert, Toggle, InputText, DropDown } from '@components';
import { useState } from 'react';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { Page } from '@layouts';
import queries from '@queries';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import moment from 'moment';
import { AdminCommon } from '../../layouts/Page/AdminCommon';
import { AdminCommonComponents } from '../../layouts';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: false },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: false
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: false },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: true },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminTimeOff() {
  const [timeOffIdSelected, setTimeOffIdSelected] = useState();

  const upsertTimeOffMutation = useMutation((data) => queries.post('/api/time-offs', data));
  const deleteTimeOffsMutation = useMutation((data) => queries.delete('/api/time-offs', data));

  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState(upsertTimeOffMutation);
  const [selectedUser, setSelectedUser] = useState({});
  const [month, setMonth] = useState(moment().month());

  const { data: timeoffData, isLoading: timeoffDataIsLoading } = useQuery(['timeoff'], () =>
    queries.getAll('/api/time-offs', { populate: ['users_permissions_user'] })
  );

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['user'], () =>
    queries.getAll('/api/users', { populate: ['teams', 'time_offs'] })
  );

  if (timeoffDataIsLoading || usersDataIsLoading) return <></>;

  const filterTimeoffsUsingComboBox = (timeoffs) =>
    timeoffs.filter(
      (t) =>
        !selectedUser?.username ||
        t?.users_permissions_user?.username
          ?.toLowerCase()
          ?.includes(selectedUser?.username?.toLowerCase())
    );

  const sortTimeOff = (t) =>
    filterTimeoffsUsingComboBox(
      t.sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix())
    );

  const _generateMonthsList = () =>
    new Array(12).fill(0).map((_, index) => ({
      label: `Month ${index + 1} - ${moment()
        .month(index)
        .startOf('month')
        .format('DD/MM/yyyy')} - ${moment().month(index).endOf('month').format('DD/MM/yyyy')}`,
      value: index
    }));

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: false },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: true, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <AdminCommon
                pageTitle="Time off"
                pageSubTitle="Time off informations"
                setOpenModal={setOpenModal}
                setIsError={setIsError}
                setSelectedId={setTimeOffIdSelected}
                tabelLabels={['User', 'Project', 'Daily Rate', 'Item Ref']}
                tableData={sortTimeOff(timeoffData?.data)
                  .filter(
                    (t) =>
                      moment(t?.startDate).format('M') == month + 1 &&
                      moment(t?.startDate).format('YYYY') == moment().year()
                  )
                  .map((o) => ({
                    id: o.id,
                    name: o?.users_permissions_user?.username,
                    date: o?.startDate,
                    endDate: o?.endDate,
                    halfDay: <Toggle name={o?.id} checked={o?.halfDay} disabled />,
                    type: o?.type,
                    status: (
                      <span
                        className={`inline-flex items-center rounded-full bg-${
                          { 'En attente': 'yellow', Validé: 'green', Refusé: 'red' }[o?.status]
                        }-100 px-2.5 py-0.5 text-xs font-medium text-${
                          { 'En attente': 'yellow', Validé: 'green', Refusé: 'red' }[o?.status]
                        }-800`}>
                        {o?.status}
                      </span>
                    )
                  }))}
                additionalFront={
                  <>
                    <div className="mt-4 w-full">
                      <p className="text-sm font-semibold text-gray-900">Filter Month:</p>
                      <DropDown
                        className="mt-3 w-full"
                        name="users_permissions_user"
                        selectName=""
                        data={_generateMonthsList()}
                        required={false}
                        defaultValue={moment().month()}
                        onChange={(e) => {
                          setMonth(parseInt(e.target.value));
                        }}
                      />
                    </div>
                    <AdminCommonComponents
                      usersData={usersData}
                      selectedUser={selectedUser}
                      setSelectedUser={setSelectedUser}
                    />
                  </>
                }></AdminCommon>
              <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                <Form
                  mutation={mutation}
                  callback={(r) => {
                    console.log(r);
                    queryClient.invalidateQueries(['timeoff']);
                    queryClient.invalidateQueries('monthsheet');
                    queryClient.invalidateQueries('project');
                    setOpenModal(false);
                  }}
                  setIsError={setIsError}
                  setIsLoading={setIsLoading}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <input type="hidden" name="id" value={timeOffIdSelected}></input>
                      {isError && (
                        <Alert color="red" className="mb-4 mt-2">
                          Impossible to update a time off.
                          <br />
                          Make sure to be connected to internet
                        </Alert>
                      )}
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-semibold text-gray-900">
                        Create or update a time off
                      </Dialog.Title>
                      <input
                        type="hidden"
                        name="users_permissions_user"
                        value={
                          timeoffData?.data?.filter((t) => t.id == timeOffIdSelected)?.[0]
                            ?.users_permissions_user?.id
                        }></input>
                      <input type="hidden" name="is_admin" value></input>

                      <div className="flex items-center text-sm lg:text-base mt-3">Start date</div>
                      <InputText
                        required={false}
                        className="grow col-span-3"
                        name="startDate"
                        defaultValue={
                          timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]
                            ?.startDate
                        }
                        classNameInput={'rounded'}
                        type="date"
                      />

                      <div className="flex items-center text-sm lg:text-base mt-3">End date</div>
                      <InputText
                        required={false}
                        className="grow col-span-3"
                        name="endDate"
                        defaultValue={
                          timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]?.endDate
                        }
                        classNameInput={'rounded'}
                        type="date"
                      />
                      <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                        Half day
                      </div>
                      <Toggle
                        name={'halfDay'}
                        className="mt-3"
                        defaultChecked={
                          timeOffIdSelected
                            ? timeoffData?.data?.filter((u) => u.id == timeOffIdSelected)?.[0]
                                ?.halfDay
                            : false
                        }
                      />
                      <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                        Type
                      </div>
                      <DropDown
                        className="mt-3 w-full"
                        name="type"
                        selectName=""
                        data={[
                          {
                            label: 'Congés payés',
                            value: 'Congés payés'
                          },
                          {
                            label: 'Congés sans solde',
                            value: 'Congés sans solde'
                          }
                        ]}
                        required={true}
                        defaultValue={
                          timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]?.type
                        }
                      />
                      <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                        Status
                      </div>
                      <DropDown
                        className="mt-3 w-full"
                        name="status"
                        selectName=""
                        data={[
                          {
                            label: 'Validé',
                            value: 'Validé'
                          },
                          {
                            label: 'Refusé',
                            value: 'Refusé'
                          },
                          {
                            label: 'En attente',
                            value: 'En attente'
                          }
                        ]}
                        required={true}
                        defaultValue={
                          timeoffData?.data?.filter((p) => p?.id == timeOffIdSelected)?.[0]?.status
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center sm:justify-between`}>
                    <Button
                      color="red"
                      onClick={() => setMutation(deleteTimeOffsMutation)}
                      className="text-white font-bold py-2 px-4 rounded"
                      isLoading={isLoading}
                      isWrong={isError}>
                      Delete
                    </Button>
                    <Button
                      form="null"
                      color="gray"
                      onClick={() => setOpenModal(false)}
                      className="text-white font-bold py-2 px-4 rounded">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="cyan"
                      onClick={() => setMutation(upsertTimeOffMutation)}
                      className="text-white font-bold py-2 px-4 rounded"
                      isLoading={isLoading}
                      isWrong={isError}>
                      Save
                    </Button>
                  </div>
                </Form>
              </Modal>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
