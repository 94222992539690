import { Modal, InputText, Form, Button, Alert } from '@components';
import { useState } from 'react';
import { Page } from '@layouts';
import { Dialog } from '@headlessui/react';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import queries from '@queries';
import { AdminCommon } from '../../layouts/Page/AdminCommon';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: false },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: true
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: false },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: false },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PublicHolidays() {
  const [daysOffIdSelected, setDaysOffIdSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  console.log(daysOffIdSelected);
  const upsertDaysOffMutation = useMutation((data) => queries.post('/api/days-offs', data));
  const deleteDaysOffsMutation = useMutation((data) => queries.delete('/api/days-offs', data));

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState(upsertDaysOffMutation);

  const { data: daysOffData, isLoading: daysOffDataIsLoading } = useQuery(['daysoff'], () =>
    queries.getAll('/api/days-offs')
  );

  if (daysOffDataIsLoading) return <></>;

  return (
    <>
      <Page
        navigation={[
          { name: 'Home', href: '/', current: false },
          { name: 'Time Tracker', href: '/time-tracker', current: false },
          { name: 'Time off', href: '/time-off', current: false },
          { name: 'Admin', href: '/admin/projects', current: true }
        ]}>
        <main className="-mt-24 pb-8">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-3">
                  <nav className="space-y-1">
                    {subNavigation.map((item) => (
                      <a
                        key={item?.name}
                        href={item?.href}
                        className={classNames(
                          item?.current
                            ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                            : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                          'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        aria-current={item?.current ? 'page' : undefined}>
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-teal-500 group-hover:text-teal-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item?.name}</span>
                      </a>
                    ))}
                  </nav>
                </aside>

                <AdminCommon
                  pageTitle="Public Holidays"
                  setOpenModal={setOpenModal}
                  buttonText=" Add Public Holiday"
                  setIsError={setIsError}
                  setSelectedId={setDaysOffIdSelected}
                  tabelLabels={['Name', 'Date']}
                  tableData={daysOffData?.data?.map((o) => ({
                    id: o.id,
                    name: o?.name,
                    date: o?.date
                  }))}>
                  <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                    <Form
                      mutation={mutation}
                      callback={(r) => {
                        console.log(r);
                        queryClient.invalidateQueries('project');
                        setOpenModal(false);
                      }}
                      setIsError={setIsError}
                      setIsLoading={setIsLoading}>
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <input type="hidden" name="id" value={daysOffIdSelected}></input>
                          {isError && (
                            <Alert color="red" className="mb-4 mt-2">
                              Impossible to create a public holiday.
                              <br />
                              Make sure to be connected to internet
                            </Alert>
                          )}
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-semibold text-gray-900">
                            Create or update a public holiday
                          </Dialog.Title>

                          <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                            Name
                          </div>
                          <InputText
                            className="grow col-span-3 mt-3"
                            name="name"
                            defaultValue={
                              daysOffIdSelected
                                ? daysOffData?.data?.filter((p) => p?.id == daysOffIdSelected)?.[0]
                                    ?.name
                                : ''
                            }
                            classNameInput={'rounded'}
                            required={false}
                          />

                          <div className="flex items-center text-sm lg:text-base mt-3">
                            Choose date
                          </div>
                          <InputText
                            required={false}
                            className="grow col-span-3"
                            name="date"
                            defaultValue={
                              daysOffData?.data?.filter((p) => p?.id == daysOffIdSelected)?.[0]
                                ?.date
                            }
                            classNameInput={'rounded'}
                            type="date"
                          />
                        </div>
                      </div>
                      <div
                        className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center sm:justify-between`}>
                        {daysOffIdSelected && (
                          <Button
                            color="red"
                            onClick={() => setMutation(deleteDaysOffsMutation)}
                            className="text-white font-bold py-2 px-4 rounded"
                            isLoading={isLoading}
                            isWrong={isError}>
                            Delete
                          </Button>
                        )}
                        <Button
                          form="null"
                          color="gray"
                          onClick={() => setOpenModal(false)}
                          className="text-white font-bold py-2 px-4 rounded">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="cyan"
                          onClick={() => setMutation(upsertDaysOffMutation)}
                          className="text-white font-bold py-2 px-4 rounded"
                          isLoading={isLoading}
                          isWrong={isError}>
                          Save
                        </Button>
                      </div>
                    </Form>
                  </Modal>
                </AdminCommon>
              </div>
            </div>
          </div>
        </main>
      </Page>
    </>
  );
}
