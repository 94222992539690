import axios from 'axios';
import { configToken } from '@queries/utils';
import baseUrl from '@root/config';

export const loginUser = (data) => axios.post(`${baseUrl}/api/auth/local`, data?.[0], configToken);
export const resetPassword = (data) =>
  axios.post(`${baseUrl}/api/auth/reset-password`, data?.[0], configToken);
export const forgotPassword = (data) =>
  axios.post(`${baseUrl}/api/auth/forgot-password`, data?.[0], configToken);
export const createUsers = (data) => axios.post(`${baseUrl}/api/users`, data?.[0], configToken);
export const updateUsers = (data) =>
  axios.put(`${baseUrl}/api/users/${data?.[0]?.id}`, data?.[0], configToken);
