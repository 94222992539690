import { Modal, InputText, Form, DropDown, Button, Alert } from '@components';
import { useState } from 'react';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { Page } from '@layouts';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import queries from '@queries';
import { AdminCommon } from '../../layouts/Page/AdminCommon';
import { AdminCommonComponents } from '../../layouts';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: true },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: false },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: false
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: false },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: false },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminTeams() {
  const createTeamsMutation = useMutation((data) => queries.post('/api/teams', data));
  const deleteTeamsMutation = useMutation((data) => queries.delete('/api/teams', data));

  const queryClient = useQueryClient();
  const [teamIdSelected, setTeamIdSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState(createTeamsMutation);
  const [selectedUser, setSelectedUser] = useState({});

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['user'], () =>
    queries.getAll('/api/users', { populate: ['teams', 'time_offs'] })
  );

  const { data: projectsData, isLoading: projectsDataIsLoading } = useQuery(['project'], () =>
    queries.getAll('/api/projects', { populate: ['teams'] })
  );

  const { data: teamsData, isLoading: teamsDataIsLoading } = useQuery(['teams'], () =>
    queries.getAll('/api/teams', {
      populate: ['users_permissions_user', 'time_entries', 'project', 'project.template']
    })
  );

  if (teamsDataIsLoading || projectsDataIsLoading || usersDataIsLoading) return <></>;

  const filterTeamsUsingComboBox = (teams) =>
    teams.filter(
      (t) =>
        !selectedUser?.username ||
        t?.users_permissions_user?.username
          ?.toLowerCase()
          ?.includes(selectedUser?.username?.toLowerCase())
    );

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: false },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: true, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>
              <AdminCommon
                pageTitle="Teams"
                pageSubTitle="Teams informations"
                buttonText="Add Teams"
                setOpenModal={setOpenModal}
                setIsError={setIsError}
                setSelectedId={setTeamIdSelected}
                tabelLabels={['User', 'Project', 'Daily Rate', 'Item Ref']}
                tableData={filterTeamsUsingComboBox(teamsData?.data).map((t) => ({
                  id: t.id,
                  userName: t.users_permissions_user?.username ?? '',
                  team: t.project?.name ?? '',
                  dailyRate: t.dailyRate ?? '',
                  itemRef: t.itemRef ?? ''
                }))}
                additionalFront={
                  <AdminCommonComponents
                    usersData={usersData}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                  />
                }>
                <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                  <Form
                    mutation={mutation}
                    callback={(r) => {
                      console.log(r);
                      queryClient.invalidateQueries('team');
                      setOpenModal(false);
                    }}
                    setIsError={setIsError}
                    setIsLoading={setIsLoading}>
                    <input type="hidden" name="id" value={teamIdSelected}></input>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {isError && (
                          <Alert color="red" className="mb-4 mt-2">
                            Impossible to create a team.
                            <br />
                            Make sure to be connected to internet
                          </Alert>
                        )}
                        <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
                          Create or update a team
                        </Dialog.Title>

                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          User
                        </div>
                        <DropDown
                          className="mt-3 w-full"
                          name="users_permissions_user"
                          selectName=""
                          data={usersData?.map((t) => ({
                            label: t?.username,
                            value: t?.id
                          }))}
                          required={false}
                          defaultValue={
                            teamIdSelected
                              ? teamsData?.data?.filter((p) => p?.id == teamIdSelected)?.[0]
                                  ?.users_permissions_user?.id
                              : ''
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Project
                        </div>
                        <DropDown
                          className="mt-3 w-full"
                          name="project"
                          selectName=""
                          data={projectsData?.data?.map((p) => ({
                            label: p?.name,
                            value: p?.id
                          }))}
                          required={false}
                          defaultValue={
                            teamsData?.data?.filter((p) => p?.id == teamIdSelected)?.[0]?.project
                              ?.id
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Daily Rate
                        </div>
                        <InputText
                          className="grow col-span-3 mt-3"
                          name="dailyRate"
                          classNameInput={'rounded'}
                          required={false}
                          defaultValue={
                            teamsData?.data?.filter((p) => p?.id == teamIdSelected)?.[0]?.dailyRate
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Item Ref
                        </div>
                        <InputText
                          className="grow col-span-3 mt-3"
                          name="itemRef"
                          classNameInput={'rounded'}
                          required={false}
                          defaultValue={
                            teamsData?.data?.filter((p) => p?.id == teamIdSelected)?.[0]?.itemRef
                          }
                        />
                      </div>
                    </div>

                    <div
                      className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center sm:justify-between`}>
                      {teamIdSelected ? (
                        <Button
                          color="red"
                          onClick={() => setMutation(deleteTeamsMutation)}
                          className="text-white font-bold py-2 px-4 rounded"
                          isLoading={isLoading}
                          isWrong={isError}>
                          Delete
                        </Button>
                      ) : (
                        <Button
                          form="null"
                          color="gray"
                          onClick={() => setOpenModal(false)}
                          className="text-white font-bold py-2 px-4 rounded">
                          Cancel
                        </Button>
                      )}
                      <Button
                        type="submit"
                        color="cyan"
                        onClick={() => setMutation(createTeamsMutation)}
                        className="text-white font-bold py-2 px-4 rounded"
                        isLoading={isLoading}
                        isWrong={isError}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </AdminCommon>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
