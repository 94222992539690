import {
  Modal,
  InputText,
  Form,
  Button,
  Alert,
  Combobox,
  InputTextarea,
  DropDown
} from '@components';
import { useState } from 'react';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  MagnifyingGlassIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { Page } from '@layouts';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import queries from '@queries';
import { AdminCommon } from '../../layouts/Page/AdminCommon';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: false },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: false
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: false },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: false },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: true }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Notifications() {
  const createNotificationMutation = useMutation((data) =>
    queries.post('/api/notifications', data)
  );
  const deleteNotificationMutation = useMutation((data) =>
    queries.delete('/api/notifications', data)
  );

  const queryClient = useQueryClient();
  const [NotificationIdSelected, setNotificationIdSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState(createNotificationMutation);
  const [selectedUser, setSelectedUser] = useState({});

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['user'], () =>
    queries.getAll('/api/users', { populate: ['teams', 'time_offs'] })
  );

  const { data: notificationsData, isLoading: notificationsDataIsLoading } = useQuery(
    ['notifications'],
    () => queries.getAll('/api/notifications', { populate: ['users_permissions_user'] })
  );

  if (usersDataIsLoading || notificationsDataIsLoading) return <></>;

  const filterNotificationUsingComboBox = (notification) =>
    notification?.filter(
      (t) =>
        !selectedUser?.username ||
        t?.users_permissions_user?.username
          ?.toLowerCase()
          ?.includes(selectedUser?.username?.toLowerCase())
    );

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: false },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: true, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <AdminCommon
                pageTitle="Notifications"
                pageSubTitle="Notification informations"
                buttonText="Add Notification"
                setOpenModal={setOpenModal}
                setIsError={setIsError}
                setSelectedId={setNotificationIdSelected}
                tabelLabels={['Date', 'Phrase', 'User']}
                tableData={filterNotificationUsingComboBox(notificationsData?.data)?.map((t) => ({
                  id: t?.id,
                  date: t?.date ?? '',
                  phrase: t.phrase ?? '',
                  user: t?.users_permissions_user?.username ?? ''
                }))}
                additionalFront={
                  <div className="mt-4 w-full">
                    <p className="text-sm font-semibold text-gray-900">Filter User:</p>
                    <Combobox
                      classNameInput="pl-10"
                      icon={MagnifyingGlassIcon}
                      data={usersData}
                      filter={(o, q) => o.username.toLowerCase().includes(q.toLowerCase())}
                      displayValue={(p) => p?.username}
                      color="blue"
                      value={selectedUser}
                      setValue={setSelectedUser}
                      displayOptions={(o) => o?.username}
                      isControlled={true}
                    />
                  </div>
                }>
                <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                  <Form
                    mutation={mutation}
                    callback={(r) => {
                      console.log(r);
                      queryClient.invalidateQueries('notifications');
                      setOpenModal(false);
                    }}
                    setIsError={setIsError}
                    setIsLoading={setIsLoading}>
                    <input type="hidden" name="id" value={NotificationIdSelected}></input>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {isError && (
                          <Alert color="red" className="mb-4 mt-2">
                            Impossible to create a notification.
                            <br />
                            Make sure to be connected to the internet
                          </Alert>
                        )}
                        <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
                          Create or update a notification
                        </Dialog.Title>
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          User
                        </div>
                        <DropDown
                          className="mt-3 w-full"
                          name="users_permissions_user"
                          selectName=""
                          data={usersData?.map((t) => ({
                            label: t?.username,
                            value: t?.id
                          }))}
                          required={false}
                          defaultValue={
                            NotificationIdSelected
                              ? notificationsData?.data?.filter(
                                  (p) => p?.id == NotificationIdSelected
                                )?.[0]?.users_permissions_user?.id
                              : ''
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Date
                        </div>
                        <InputText
                          className="grow col-span-3 mt-3"
                          name="date"
                          type="date"
                          classNameInput={'rounded'}
                          required={false}
                          defaultValue={
                            notificationsData?.data?.filter(
                              (p) => p?.id == NotificationIdSelected
                            )?.[0]?.date
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Phrase
                        </div>
                        <InputTextarea
                          className="grow col-span-3 mt-3"
                          name="phrase"
                          classNameInput={'rounded h-28'}
                          required={true}
                          defaultValue={
                            notificationsData?.data?.filter(
                              (p) => p?.id == NotificationIdSelected
                            )?.[0]?.phrase
                          }
                        />
                      </div>
                    </div>

                    <div
                      className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center sm:justify-between`}>
                      {NotificationIdSelected && (
                        <Button
                          color="red"
                          onClick={() => setMutation(deleteNotificationMutation)}
                          className="text-white font-bold py-2 px-4 rounded"
                          isLoading={isLoading}
                          isWrong={isError}>
                          Delete
                        </Button>
                      )}
                      <Button
                        form="null"
                        color="gray"
                        onClick={() => setOpenModal(false)}
                        className="text-white font-bold py-2 px-4 rounded">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="cyan"
                        onClick={() => setMutation(createNotificationMutation)}
                        className="text-white font-bold py-2 px-4 rounded"
                        isLoading={isLoading}
                        isWrong={isError}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Modal>
              </AdminCommon>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
