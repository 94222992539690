import { Combobox } from '@components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export const AdminCommonComponents = ({ usersData, selectedUser, setSelectedUser }) => {
  return (
    <>
      <div className="mt-4 w-full">
        <p className="text-sm font-semibold text-gray-900">Filter User:</p>
        <Combobox
          classNameInput="pl-10"
          icon={MagnifyingGlassIcon}
          data={usersData}
          filter={(o, q) => o.username.toLowerCase().includes(q.toLowerCase())}
          displayValue={(p) => p?.username}
          color="blue"
          value={selectedUser}
          setValue={setSelectedUser}
          displayOptions={(o) => o?.username}
          isControlled={true}
        />
      </div>
    </>
  );
};
