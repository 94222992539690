import { SingleCombobox, MultipleCombobox } from '@components';

const Combobox = (props) => {
  const { multiple = false, ...rest } = props;

  if (multiple) return <MultipleCombobox {...rest} />;
  else return <SingleCombobox {...rest} />;
};

export default Combobox;
