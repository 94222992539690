import '@root/locale';
import moment from 'moment';
import { useState } from 'react';
import { Button, DropDown } from '@components';
import { ArrowSmallLeftIcon, ArrowSmallRightIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queries from '@queries';

export default function CalenderMonth(props) {
  const {
    showAllMonth = false,
    users = [],
    week = moment().week(),
    daysOffData = [],
    setViewCalendarBy,
    viewCalendarBy,
    setWeek
  } = props;
  const navigate = useNavigate();

  const [month, setMonth] = useState(moment().month());
  const [timeoffObject, setTimeoffObject] = useState({ firstDate: '', lastDate: '' });
  const [hoverCubeDate, setHoverCubeDate] = useState('');

  const currentUser = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });

  const colorCalender = (rowUserData, colIndex) => {
    const currDate = showAllMonth
      ? moment().month(month).startOf('month').add(colIndex, 'd')
      : moment().week(week).startOf('week').add(colIndex, 'd');

    for (const element of rowUserData.time_offs) {
      const flag = enumerateDaysBetweenDates(element?.startDate, element?.endDate)?.includes(
        currDate.format('yyyy-MM-DD')
      );

      if (flag && element?.status === 'Validé') {
        return 'bg-green-500';
      } else if (flag && element?.status === 'En attente') return 'bg-orange-500';
    }

    for (const element of rowUserData.sick_days) {
      const flag = enumerateDaysBetweenDates(element?.startDate, element?.endDate)?.includes(
        currDate.format('yyyy-MM-DD')
      );

      if (flag) {
        return 'bg-yellow-300';
      }
    }

    if (daysOffData?.data?.map((d) => d.date).includes(currDate.format('YYYY-MM-DD'))) {
      return 'bg-red-500';
    }

    if (currDate.isoWeekday() == 7 || currDate.isoWeekday() == 6) {
      return 'bg-gray-400';
    }

    return colorTimeOffDays(currDate.format('YYYY-MM-DD'), rowUserData);
  };

  const colorTimeOffDays = (date, timeoff) => {
    if (date == timeoffObject?.firstDate && timeoff?.id == currentUser?.data?.id) {
      return 'bg-green-300';
    } else {
      return 'bg-gray-200';
    }
  };

  const colorHovered = (colIndex) => {
    const currDate = showAllMonth
      ? moment().month(month).startOf('month').add(colIndex, 'd')
      : moment().week(week).startOf('week').add(colIndex, 'd');

    if (moment(currDate.format('YYYY-MM-DD')).isBetween(timeoffObject?.firstDate, hoverCubeDate)) {
      return 'bg-green-300';
    }
  };

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    dates.push(startDate);
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      if (currDate.isoWeekday() != 7 && currDate.isoWeekday() != 6) {
        dates.push(moment(currDate).format('YYYY-MM-DD'));
      }
    }
    return dates;
  };

  const _generateDays = () =>
    new Array(showAllMonth ? moment().month(month).daysInMonth() : 7)
      .fill(0)
      .map((_, index) =>
        showAllMonth
          ? moment().month(month).startOf('month').add(index, 'd').format('DD/MM')
          : moment().week(week).startOf('week').add(index, 'd').format('DD/MM')
      );

  return (
    <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 sm:px-6 lg:px-8 mt-4 mb-4 sm:mt-6 lg:mt-8">
          <div className="flex items-center text-sm lg:text-base mt-3 font-medium text-gray-900">
            <div className="flex flex-auto">
              <div className="flex block w-28 bg-gray-200 text-white font-semibold justify-center mr-5">
                {' '}
                Working
              </div>
              <div className="flex block w-28 bg-yellow-300 text-white font-semibold justify-center mr-5">
                {' '}
                Sick day
              </div>
              <div className="flex block w-28 bg-orange-500 text-white font-semibold justify-center mr-5">
                {' '}
                En attente
              </div>
              <div className="flex block w-28 bg-green-500 text-white font-semibold justify-center mr-5">
                {' '}
                Vacation
              </div>
              <div className="flex block w-28 bg-red-500 text-white font-semibold justify-center mr-5">
                {' '}
                BankHoliday
              </div>
            </div>

            <div className="mr-5">
              <Button
                onClick={() => (showAllMonth ? setMonth(month - 1) : setWeek(parseInt(week) - 1))}
                textColor="gray"
                color="white"
                className="border-gray-300 hover:bg-gray-50">
                <ArrowSmallLeftIcon className="h-6 w-6 text-gray-500" />
              </Button>

              <Button
                onClick={() => (showAllMonth ? setMonth(month + 1) : setWeek(parseInt(week) + 1))}
                textColor="gray"
                color="white"
                className="border-gray-300 hover:bg-gray-50 ml-2">
                <ArrowSmallRightIcon className="h-6 w-6 text-gray-500 " />
              </Button>
            </div>

            <DropDown
              className="w-40"
              data={[
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' }
              ]}
              defaultValue={viewCalendarBy}
              required={false}
              onChange={(e) => setViewCalendarBy(e.target.value)}
              value={viewCalendarBy}
              isControlled
            />
          </div>

          <div className="table-responsive mt-4 pb-0 overflow-x-auto">
            <table className="table table-hover w-full border-collapse mb-3">
              <thead>
                <tr>
                  <th className="text-left text-sm font-semibold text-gray-900">Days:</th>
                  {_generateDays().map((e, daysIndex) => (
                    <th
                      className="text-center px-3 text-sm font-semibold text-gray-900"
                      key={daysIndex}>
                      {e}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users?.map((rowElement, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      <td className="text-sm text-gray-500 border-b">{rowElement?.username}</td>
                      {new Array(showAllMonth ? moment().month(month).daysInMonth() : 7)
                        .fill(0)
                        .map((_, colIndex) => {
                          return (
                            <td key={colIndex}>
                              <div
                                onClick={() => {
                                  if (rowElement?.id == currentUser?.data?.id) {
                                    if (timeoffObject?.firstDate) {
                                      navigate('/time-off', {
                                        state: {
                                          ...timeoffObject,
                                          lastDate: showAllMonth
                                            ? moment()
                                                .month(month)
                                                .startOf('month')
                                                .add(colIndex, 'd')
                                                .format('YYYY-MM-DD')
                                            : moment()
                                                .week(week)
                                                .startOf('week')
                                                .add(colIndex, 'd')
                                                .format('YYYY-MM-DD')
                                        }
                                      });
                                    } else {
                                      setTimeoffObject({
                                        ...timeoffObject,
                                        firstDate: showAllMonth
                                          ? moment()
                                              .month(month)
                                              .startOf('month')
                                              .add(colIndex, 'd')
                                              .format('YYYY-MM-DD')
                                          : moment()
                                              .week(week)
                                              .startOf('week')
                                              .add(colIndex, 'd')
                                              .format('YYYY-MM-DD')
                                      });
                                    }
                                  }
                                }}
                                className={`block grow w-full ${colorCalender(
                                  rowElement,
                                  colIndex
                                )} ${
                                  rowElement?.id == currentUser?.data?.id
                                    ? 'hover:bg-green-300 ' + colorHovered(colIndex)
                                    : ''
                                }`}
                                onMouseOver={() =>
                                  rowElement?.id == currentUser?.data?.id &&
                                  timeoffObject?.firstDate
                                    ? setHoverCubeDate(
                                        showAllMonth
                                          ? moment()
                                              .month(month)
                                              .startOf('month')
                                              .add(colIndex, 'd')
                                              .format('YYYY-MM-DD')
                                          : moment()
                                              .week(week)
                                              .startOf('week')
                                              .add(colIndex, 'd')
                                              .format('YYYY-MM-DD')
                                      )
                                    : {}
                                }>
                                &nbsp;
                              </div>
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
