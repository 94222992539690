import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import GoogleAuthCallback from './GoogleAuthCallback';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  Login,
  HomePage,
  AdminProjects,
  AdminTeams,
  AdminUsers,
  PublicHolidays,
  TimeTracker,
  Summary,
  TimeOff,
  AdminTimeOff,
  AdminNews,
  AdminSickDays,
  Notifications
} from '@pages';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth/google/callback" element={<GoogleAuthCallback />}></Route>
          <Route
            exact
            path="/"
            element={
              <Login>
                <HomePage />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/projects"
            element={
              <Login isAdmin>
                <AdminProjects />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/teams"
            element={
              <Login isAdmin>
                <AdminTeams />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/users"
            element={
              <Login isAdmin>
                <AdminUsers />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/public-holidays"
            element={
              <Login isAdmin>
                <PublicHolidays />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/summary"
            element={
              <Login isAdmin>
                <Summary />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/timeoffs"
            element={
              <Login isAdmin>
                <AdminTimeOff />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/news"
            element={
              <Login isAdmin>
                <AdminNews />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/sickdays"
            element={
              <Login isAdmin>
                <AdminSickDays />
              </Login>
            }></Route>
          <Route
            exact
            path="/admin/notifications"
            element={
              <Login isAdmin>
                <Notifications />
              </Login>
            }></Route>
          <Route
            exact
            path="/time-tracker"
            element={
              <Login>
                <TimeTracker />
              </Login>
            }></Route>
          <Route
            exact
            path="/time-off"
            element={
              <Login>
                <TimeOff />
              </Login>
            }></Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
