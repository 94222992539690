import { useRef, useState } from 'react';
import { Badge } from '@components';

// eslint-disable-next-line max-lines-per-function
const MultipleInputFile = (props) => {
  const {
    label = '',
    accept = '*',
    setFile = () => {},
    limit = 1,
    defaultValue = [],
    ...rest
  } = props;

  const input = useRef(null);
  const [previews, setPreviews] = useState(defaultValue);

  const removeFromPreview = (index) => {
    setPreviews((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    removeFileFromFileList(index);
  };

  const removeFileFromFileList = (index) => {
    const dt = new DataTransfer();
    const { files } = input.current;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
    }

    input.current.files = dt.files; // Assign the updates list
  };

  const renderAccept = (str) =>
    str === '*'
      ? ''
      : str
          .split(',')
          .map((o) => o.replace('.', '').toUpperCase())
          .join(', ');

  const cancelEffect = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const updatePreview = (file) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => setPreviews((prev) => [...prev, reader.result]), false);

    if (['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type))
      reader.readAsDataURL(file);
    else setPreviews((prev) => [...prev, file.name]);
  };

  const uploadFile = (e, type = 'click') => {
    e.preventDefault();
    e.stopPropagation();

    let files = null;

    if (type === 'click') files = e.target.files;
    else files = e.dataTransfer.files;

    setFile(files);
    setPreviews([]);

    // Limit the number of files to the number indicated in the file
    for (let i = 0; i < Math.min(files.length, limit); i++) {
      updatePreview(files.item(i));
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {previews.map((o, index) => (
        <Badge key={index} className="mt-1 mr-2" onClick={() => removeFromPreview(index)}>
          {['data', '.png', '.jpeg', '.jpg', '.gif', '.svg'].some((l) => o.includes(l)) ? (
            <img className="h-[50px] m-2" src={o} />
          ) : (
            o
          )}
        </Badge>
      ))}

      <div
        className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${
          previews.length >= limit && 'hidden'
        }`}
        onDrag={(e) => cancelEffect(e)}
        onDragEnter={(e) => cancelEffect(e)}
        onDragLeave={(e) => cancelEffect(e)}
        onDragEnd={(e) => cancelEffect(e)}
        onDragOver={(e) => cancelEffect(e)}
        onDragStart={(e) => cancelEffect(e)}
        onDrop={(e) => uploadFile(e, 'DND')}>
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              <span>Upload a file</span>
              <input
                ref={input}
                type="file"
                className="sr-only"
                accept={accept}
                onChange={(e) => uploadFile(e)}
                {...rest}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">{renderAccept(accept)}</p>
        </div>
      </div>
    </div>
  );
};

export default MultipleInputFile;
