import { Modal, Form, Button, Alert, Toggle, InputText } from '@components';
import { useState } from 'react';
import {
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ClipboardIcon,
  HomeIcon,
  NewspaperIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline';
import { Page } from '@layouts';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import queries from '@queries';
import { updateUsers } from '@queries/User';
import { AdminCommon } from '../../layouts/Page/AdminCommon';

const subNavigation = [
  { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentCheckIcon, current: false },
  { name: 'Teams', href: '/admin/teams', icon: UserGroupIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserPlusIcon, current: true },
  {
    name: 'Public Holidays',
    href: '/admin/public-holidays',
    icon: CalendarDaysIcon,
    current: false
  },
  { name: 'Summary', href: '/admin/summary', icon: ChartPieIcon, current: false },
  { name: 'Time off', href: '/admin/timeoffs', icon: ClipboardIcon, current: false },
  { name: 'News', href: '/admin/news', icon: NewspaperIcon, current: false },
  { name: 'Sick Days', href: '/admin/sickdays', icon: HomeIcon, current: false },
  { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminUsers() {
  const [userIdSelected, setUserIdSelected] = useState();

  const updateUsersMutation = useMutation((data) => updateUsers(data));
  const deleteUsersMutation = useMutation((data) => queries.delete('/api/users', data));

  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mutation, setMutation] = useState();

  const { data: usersData, isLoading: usersDataIsLoading } = useQuery(['user'], () =>
    queries.getAll('/api/users', { populate: ['teams', 'time_offs'] })
  );

  if (usersDataIsLoading) return <></>;

  return (
    <Page
      navigation={[
        { name: 'Home', href: '/', current: false },
        { name: 'Time Tracker', href: '/time-tracker', current: false },
        { name: 'Time off', href: '/time-off', current: false },
        { name: 'Admin', href: '/admin/projects', current: true, isAdmin: true }
      ]}>
      <main className="-mt-24 pb-8">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <AdminCommon
                pageTitle="Users"
                pageSubTitle="Users informations"
                setOpenModal={setOpenModal}
                setIsError={setIsError}
                setSelectedId={setUserIdSelected}
                tabelLabels={['User', 'isAdmin', 'Employee Ref', 'Date entry', 'Payroll']}
                tableData={usersData?.map((u, index) => ({
                  id: u.id,
                  userName: u.username,
                  isAdmin: <Toggle name={u.id} checked={u.isAdmin} disabled />,
                  employeeRef: u.employeeRef,
                  dateEntry: u?.dateEntry,
                  payroll: <Toggle name={index} checked={u.payroll} disabled />
                }))}>
                <Modal isOpen={openModal} setIsOpen={setOpenModal}>
                  <Form
                    id="form_user"
                    mutation={mutation}
                    callback={(r) => {
                      console.log(r);
                      queryClient.invalidateQueries('user');
                      setOpenModal(false);
                      setOpenDeleteModal(false);
                    }}
                    setIsError={setIsError}
                    setIsLoading={setIsLoading}>
                    <input type="hidden" name="id" value={userIdSelected}></input>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {isError && (
                          <Alert color="red" className="mb-4 mt-2">
                            Impossible to create a user.
                            <br />
                            Make sure to be connected to internet
                          </Alert>
                        )}
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900">
                          Create or update a user
                        </Dialog.Title>

                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          User:
                          {` ${usersData?.filter((p) => p.id == userIdSelected)[0]?.username}` ??
                            ''}
                        </div>
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          isAdmin
                        </div>
                        <Toggle
                          name={'isAdmin'}
                          className="mt-3"
                          defaultChecked={
                            userIdSelected
                              ? usersData?.filter((u) => u.id == userIdSelected)?.[0]?.isAdmin
                              : false
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Employee Ref
                        </div>
                        <InputText
                          className="grow col-span-3 mt-3"
                          name="employeeRef"
                          classNameInput={'rounded'}
                          required={false}
                          defaultValue={
                            usersData?.filter((u) => u?.id == userIdSelected)?.[0]?.employeeRef
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Date Entry
                        </div>
                        <InputText
                          className="grow col-span-3 mt-3"
                          type="date"
                          name="dateEntry"
                          classNameInput={'rounded'}
                          required={false}
                          defaultValue={
                            usersData?.filter((u) => u?.id == userIdSelected)?.[0]?.dateEntry
                          }
                        />
                        <div className="flex items-center text-sm text-gray-700 lg:text-base mt-3">
                          Payroll
                        </div>
                        <Toggle
                          name={'payroll'}
                          className="mt-3"
                          defaultChecked={
                            userIdSelected
                              ? usersData?.filter((u) => u.id == userIdSelected)?.[0]?.payroll
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div
                      className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center ${
                        userIdSelected ? 'sm:justify-between' : 'sm:justify-end'
                      }`}>
                      {userIdSelected && (
                        <Button
                          color="red"
                          onClick={(e) => {
                            setOpenDeleteModal(true);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          className="text-white font-bold py-2 px-4 rounded"
                          isLoading={isLoading}
                          isWrong={isError}>
                          Delete
                        </Button>
                      )}
                      <Button
                        form="null"
                        color="gray"
                        onClick={() => setOpenModal(false)}
                        className="text-white font-bold py-2 px-4 rounded">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="cyan"
                        onClick={() => setMutation(updateUsersMutation)}
                        className="text-white font-bold py-2 px-4 rounded"
                        isLoading={isLoading}
                        isWrong={isError}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Modal>
                <Modal isOpen={openDeleteModal} setIsOpen={setOpenDeleteModal}>
                  <div
                    className={`bg-gray-50 px-4 py-3  sm:px-6 sm:flex sm:flex-row sm:content-center ${
                      userIdSelected ? 'sm:justify-between' : 'sm:justify-end'
                    }`}>
                    <Button
                      form="form_user"
                      color="red"
                      onClick={() => {
                        setMutation(deleteUsersMutation);
                      }}
                      className="text-white font-bold py-2 px-4 rounded"
                      isLoading={isLoading}
                      isWrong={isError}>
                      Delete
                    </Button>
                    <Button
                      form="null"
                      color="gray"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenDeleteModal(false);
                      }}
                      className="text-white font-bold py-2 px-4 rounded"
                      isLoading={isLoading}
                      isWrong={isError}>
                      Cancel
                    </Button>
                  </div>
                </Modal>
              </AdminCommon>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}
